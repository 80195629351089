<template>
	<div class="page">
		<el-form>
			<el-row>
				<el-col class="col-xl">
					<el-form-item size="large">
						<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
							<el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
						</el-tooltip>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row :gutter="20">
			<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
				<el-table v-if="tabela.polazak.length"
							:data="tabela.polazak"
							ref="polazak"
							v-loading="gridLoad"
							:row-class-name="tableRowClass"
							border>
					<el-table-column
							align="left"
							:label="$t('cene.polazak')">
						<template slot-scope="scope">
							{{$utils('formatDate', scope.row.datum)}}
						</template>
					</el-table-column>
					<el-table-column
							:label="$t('cene.zadnjaCena')"
							align="center">
						<template slot-scope="scope">
							{{$utils('formatBroja', scope.row.zadnja_cena)}}
						</template>
					</el-table-column>
					<el-table-column
							:label="$t('cene.isplativost')"
							align="center">
						<template slot-scope="scope">
							{{$utils('formatBroja', scope.row.ukupno_prodato + scope.row.ukupno_doplata)}} / {{$utils('formatBroja', scope.row.isplativost)}}
						</template>
					</el-table-column>
					<el-table-column
							:label="$t('cene.raspolozivo')"
							align="right">
						<template slot-scope="scope">
							{{scope.row.raspolozivih}}
						</template>
					</el-table-column>
					<el-table-column
							align="left"
							width="200">
						<template slot-scope="scope">
							<el-tooltip class="item" effect="dark" :content="$t('global.izmena')" placement="top">
								<el-button type="success" icon="fas fa-pen" @click="$utils('goTo', '/cene/cena/' + $route.params.id + '/dan/' + scope.row.id + '/datum/' + scope.row.datum + '/pravac/polazak')" size="small"></el-button>
							</el-tooltip>
							<el-button type="info" icon="fas fa-info" @click="getInfoDana(scope.row.id, 'polazak', scope.row)" size="small"></el-button>
							<!-- ++++++ -->
							<el-button @click="ceneDaniStatus(scope.row.id)" v-if="scope.row.aktivno === 'DA'" type="danger" size="small"><i class="fas fa-times"></i></el-button>
							<el-button @click="ceneDaniStatus(scope.row.id)" v-else-if="scope.row.aktivno === 'NE'" type="success" size="small"><i class="fas fa-check"></i></el-button>
							<!-- ++++++ -->
							<el-tooltip class="item" v-if="scope.row.preskok" effect="dark" :content="$t('cene.preskok')" placement="top">
								<i class="fas fa-share preskok"></i>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
				<br>
				<el-pagination
						align="center"
						v-show="pagination.polazak.total"
						:background="true"
						@current-change="polazakCurrentChange"
						:current-page.sync="pagination.polazak.currentPage"
						:page-size="pagination.polazak.pageSize"
						layout="total, prev, pager, next"
						:total="pagination.polazak.total">
				</el-pagination>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
				<el-table v-if="tabela.povratak.length"
							:data="tabela.povratak"
							ref="povratak"
							v-loading="gridLoad"
							:row-class-name="tableRowClass"
							border>
					<el-table-column
							align="left"
							:label="$t('cene.povratak')">
						<template slot-scope="scope">
							{{$utils('formatDate', scope.row.datum)}}
						</template>
					</el-table-column>
					<el-table-column
							:label="$t('cene.zadnjaCena')"
							align="center">
						<template slot-scope="scope">
							{{$utils('formatBroja', scope.row.zadnja_cena)}}
						</template>
					</el-table-column>
					<el-table-column
							:label="$t('cene.isplativost')"
							align="center">
						<template slot-scope="scope">
							{{$utils('formatBroja', scope.row.ukupno_prodato + scope.row.ukupno_doplata)}} / {{$utils('formatBroja', scope.row.isplativost)}}
						</template>
					</el-table-column>
					<el-table-column
							:label="$t('cene.raspolozivo')"
							align="right">
						<template slot-scope="scope">
							{{scope.row.raspolozivih}}
						</template>
					</el-table-column>
					<el-table-column
							align="left"
							width="200">
						<template slot-scope="scope">
							<el-tooltip class="item" effect="dark" :content="$t('global.izmena')" placement="top">
								<el-button type="success" icon="fas fa-pen" @click="$utils('goTo', '/cene/cena/' + $route.params.id + '/dan/' + scope.row.id + '/datum/' + scope.row.datum + '/pravac/povratak')" size="small"></el-button>
							</el-tooltip>
							<el-button type="info" icon="fas fa-info" @click="getInfoDana(scope.row.id, 'povratak', scope.row)" size="small"></el-button>
							<!-- ++++++ -->
							<el-button @click="ceneDaniStatus(scope.row.id)" v-if="scope.row.aktivno === 'DA'" type="danger" size="small"><i class="fas fa-times"></i></el-button>
							<el-button @click="ceneDaniStatus(scope.row.id)" v-else-if="scope.row.aktivno === 'NE'" type="success" size="small"><i class="fas fa-check"></i></el-button>
							<!-- ++++++ -->
							<el-tooltip class="item" v-if="scope.row.preskok" effect="dark" :content="$t('cene.preskok')" placement="top">
								<i class="fas fa-share preskok"></i>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
				<br>
				<el-pagination
						align="center"
						v-show="pagination.povratak.total"
						:background="true"
						@current-change="povratakCurrentChange"
						:current-page.sync="pagination.povratak.currentPage"
						:page-size="pagination.povratak.pageSize"
						layout="total, prev, pager, next"
						:total="pagination.povratak.total">
				</el-pagination>
			</el-col>
		</el-row>
		<el-dialog
				:title="dialogData.datum"
				:visible.sync="dialogVisible"
				width="45%">
			<div v-loading="dialogLoading">
				<div>
					<div v-if="dialogData.cene.length && dialogData.parametri.vrsta_cene !== 'fiksna'">
						<el-table :data="dialogData.cene"
									border
									stripe
									style="width: 100%">
							<el-table-column
									align="right"
									prop="cena"
									:label="$t('cene.cena')">
								<template slot-scope="scope">
									{{$utils('formatBroja', scope.row.cena)}}
								</template>
							</el-table-column>
							<el-table-column
									align="center"
									width="180"
									prop="raspolozivo"
									:label="$t('cene.brojRaspolozivih')">
							</el-table-column>
							<el-table-column
									align="center"
									prop="iskorisceno"
									:label="$t('cene.iskorisceno')">
							</el-table-column>
							<el-table-column
									width="120"
									:label="$t('cene.popuniCenu')"
									align="center">
								<template slot-scope="scope">
									<el-button size="mini" icon="el-icon-close" type="danger" :disabled="dialogData.aktivno === 'NE'" @click="getPopuniCenu(scope.row.cena)"/>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div v-else-if="dialogData.parametri.vrsta_cene === 'fiksna'">
						<table cellspacing="10">
							<tr>
								<td><h2>{{$t('cene.cena')}}</h2></td>
								<td><h2>:</h2></td>
								<td class="text-right"><h2>{{$utils('formatBroja', dialogData.parametri.cena_fiksna)}}</h2></td>
							</tr>
							<tr>
								<td><h2>{{$t('cene.brojRaspolozivih')}}</h2></td>
								<td><h2>:</h2></td>
								<td class="text-right"><h2>{{dialogData.parametri.broj_raspolozivih}}</h2></td>
							</tr>
							<tr>
								<td><h2>{{$t('cene.iskorisceno')}}</h2></td>
								<td><h2>:</h2></td>
								<td class="text-right"><h2>{{dialogData.parametri.broj_prodatih}}</h2></td>
							</tr>
							<tr>
								<td><h2>{{$t('cene.popuniCenu')}}</h2></td>
								<td><h2>:</h2></td>
								<td class="text-right"><el-button size="mini" type="danger" icon="el-icon-close" :disabled="dialogData.aktivno === 'NE'" @click="getPopuniCenu(dialogData.parametri.cena_fiksna)"/></td>
							</tr>
						</table>
					</div>
					<el-divider></el-divider>
					<table cellspacing="10">
						<tr>
							<td><h2>{{$t('cene.ukupnoIzTabele')}}</h2></td>
							<td><h2>:</h2></td>
							<td class="text-right"><h2>{{$utils('formatBroja', dialogData.prodate_cifra)}}</h2></td>
						</tr>
						<tr>
							<td><h2>{{$t('cene.ostaleKorekcije')}} </h2></td>
							<td><h2>:</h2></td>
							<td class="text-right"><h2>{{$utils('formatBroja', dialogData.ukupno_prodato - dialogData.samo_povratne - dialogData.prodate_cifra)}}</h2></td>
						</tr>
						<tr>
							<td><h2>{{$t('cene.doplate')}} </h2></td>
							<td><h2>:</h2></td>
							<td class="text-right"><h2>{{$utils('formatBroja', dialogData.ukupno_doplata)}}</h2></td>
						</tr>
						<tr>
							<td><h2>{{$t('cene.povratneKarte')}} </h2></td>
							<td><h2>:</h2></td>
							<td class="text-right"><h2>{{$utils('formatBroja', dialogData.samo_povratne)}}</h2></td>
						</tr>
						<tr>
							<td><h2>{{$t('cene.total')}} </h2></td>
							<td><h2>:</h2></td>
							<td class="text-right"><h2>{{$utils('formatBroja', dialogData.ukupno_prodato + dialogData.ukupno_doplata)}}</h2></td>
						</tr>
					</table>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">{{$t('global.zatvori')}}</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'dani-list',
	data() {
		return {
			dialogVisible: false,
			dialogLoading: false,
			idCeneDana: null,
			pravac: null,
			dialogData: {
				datum: '',
				cene: [],
				prodate_cifra: null,
				ostale_korekcije: null,
				ukupno_doplata: null,
				ukupno_prodato: null,
				samo_povratne: null,
				parametri: []
			},
			data: {
				polazak: [],
				povratak: []
			},
			tabela: {
				polazak: [],
				povratak: []
			},
			pagination: {
				polazak: {
					currentPage: 1,
					pageSize: 10,
					total: 0
				},
				povratak: {
					currentPage: 1,
					pageSize: 10,
					total: 0
				}
			},
			gridLoad: false,
			gridLoadVirtuelno: false
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('cene.cena')
		});
		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getDani()]);
	},
	methods: {
		getDani() {
			if (!this.$route.params.id)
				return;

			this.gridLoad = true;
			return this.$get('ceneDaniLista', { idCenovnika: this.$route.params.id }).then(data => {
				this.data.polazak = data.polazak;
				this.data.povratak = data.povratak;
				this.polazakCurrentChange(1);
				this.povratakCurrentChange(1);
				this.gridLoad = false;
			});
		},
		getInfoDana(idCeneDana, pravac, podaci = null) {
			if (!idCeneDana || !pravac)
				return;
			this.dialogVisible = true;
			this.dialogLoading = true;
			this.$get('ceneDani', { idCeneDana: idCeneDana, pravac: pravac }).then(data => {
				// // console.log(data); // slika1
				// datum: "2020-02-21"
				// id: 28948
				// isplativost: 500
				// preskok: true
				// raspolozivih: 50
				// samo_povratne: 0
				// ukupno_doplata: 0
				// ukupno_prodato: 758.55
				// zadnja_cena: 24.9
				// // console.log(podaci); // slika2
				// datum: "2020-02-21 00:00:00"
				// id: 28948
				// id_cene_parametri: 53
				// linija: 14
				// naziv: "TEST"
				// parametri: Object { vrsta_cene: Getter & Setter, cena_fiksna: Getter & Setter, cena_min: Getter & Setter, … }
				// preskok: true
				// prodate_cifra: 24.9
				// zadnja_cena: 24.9

				// Ukupno iz tabele: // podaci.prodate_cifra
				// Ostale korekcije: // data.ukupno_prodato - data.samo_povratne - podaci.prodate_cifra
				// Doplate: // data.ukupno_doplata
				// Povratne karte: // data.samo_povratne
				// Total: // data.ukupno_prodato + data.ukupno_doplata
				this.idCeneDana = idCeneDana;
				this.pravac = pravac;
				this.dialogData.datum = this.$utils('formatDate', data.datum); // 2019-01-16 00:00:00
				this.dialogData.parametri = data ? (data.parametri ? data.parametri : []) : [];
				this.dialogData.cene = data ? (data.parametri ? (data.parametri.cene || []) : []) : [];
				this.dialogData.prodate_cifra = data.prodate_cifra;
				this.dialogData.aktivno = data.aktivno;
				if (podaci) {
					this.dialogData.ukupno_doplata = podaci.ukupno_doplata;
					this.dialogData.samo_povratne = podaci.samo_povratne;
					this.dialogData.ukupno_prodato = podaci.ukupno_prodato;
					this.dialogData.ostale_korekcije = podaci.ukupno_prodato - data.prodate_cifra - podaci.samo_povratne;
				}
				this.dialogLoading = false;
			});
		},
		getPopuniCenu(cena) {
			this.$get('cenePopuniCenu', { idCeneDana: this.idCeneDana, cena: cena }).then(data => {
				if (data) {
					this.getInfoDana(this.idCeneDana, this.pravac);
					this.getDani();
				}
			});
		},
		ceneDaniStatus(id) {
			this.$api.ceneDaniStatus.update({ idCeneDana: id }, {}).then((data) => {
				this.getDani();
			});
		},
		tableRowClass({ row }) {
			let red = row.isplativost - row.ukupno_prodato - row.ukupno_doplata;
			if (red <= 0)
				return 'warning-green';
			if (row.aktivno === 'NE')
				return 'warning-red';
		},
		/**
		 * Paginacija Lokalna
		 * @param items
		 * @param page
		 * @param perPage
		 * @returns {{total: *, data: *, currentPage: *}}
		 */
		getPaginatedItems(items, page, perPage = 10) {
			page = page || 1;
			let offset = (page - 1) * perPage;
			let paginatedItems = _.isArray(items) ? _.drop(items, offset).slice(0, perPage) : [];
			return {
				currentPage: page,
				total: items.length,
				data: paginatedItems
			};
		},
		polazakCurrentChange(page) {
			let pagination = this.getPaginatedItems(this.data.polazak, page, this.pagination.polazak.pageSize);

			this.pagination.polazak.total = pagination.total;
			this.pagination.polazak.currentPage = pagination.currentPage;
			this.tabela.polazak = pagination.data;
		},
		povratakCurrentChange(page) {
			let pagination = this.getPaginatedItems(this.data.povratak, page, this.pagination.povratak.pageSize);

			this.pagination.povratak.total = pagination.total;
			this.pagination.povratak.currentPage = pagination.currentPage;
			this.tabela.povratak = pagination.data;
		}
	}
};
</script>


<style lang="scss">
	.preskok {
		color: red;
		padding-left: 15px;
	}
	.el-table .warning-green {
		background-color: #90D841;
	}
	.el-table .warning-red {
		background-color: rgba(255, 58, 69, 0.71);
		color: white;
		&:hover {
			color: #606266;
		}
	}
</style>
